<template>
    <v-container>
        <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="10"
        hide-default-footer
        class="elevation-1"
        :loading="loading"
        >
            <template v-slot:top>
                <v-toolbar flat color="#2961ff" dark>
                    <v-toolbar-title>All Users</v-toolbar-title>
                    <v-btn
                    light
                    elevation="0"
                    class="ml-2"
                    @click="$router.push('/admin/users/new')"
                    >New</v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }" >
                <template v-if="$store.getters.getUser._id == item._id">
                    <router-link to="/admin/profile"><v-icon small class="mr-2">fas fa-pen</v-icon></router-link>
                </template>
                <template v-else>
                    <router-link :to="`/admin/users/edit/${item._id}`"><v-icon small class="mr-2">fas fa-pen</v-icon></router-link>
                </template>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="fas fa-caret-left"
            next-icon="fas fa-caret-right"
            color="blue accent-4"
            class="mt-4 mb-4"
            :disabled="loading"
        ></v-pagination>
        <v-snackbar v-model="delted" :multi-line="true" color="red accent-2">
            Account Deleted
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="delted = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    watch:{
        page:async function(){
            this.loading = true;
            let res = await this.$http.get(`${process.env.VUE_APP_API_ROOT}/users/all?sortBy=_id&page=${this.page}`);
            this.users = res.data.data;
            this.pageCount = res.data.totalPages;
            this.loading = false;
        }
    },
    data() {
        return{
            loading:true,
            users:[],
            page:1,
            error:"",
            delted:false,
            pageCount:0,
            headers:[
                { text: 'name', align: 'start', sortable: false, value: 'name'},
                { text: 'email', align: 'start', sortable: false, value: 'email'},
                { text: 'Enabled', align: 'start', sortable: false, value: 'status'},
                { text: 'Role', align: 'start', sortable: false, value: 'role'},
                { text: 'Actions', align: 'start', sortable: false, value: 'actions'},
            ]
        }
    },
    async mounted(){
        if(this.$route.query.accountDeleted) this.delted = true;
        try{
            let res = await this.$http.get(`${process.env.VUE_APP_API_ROOT}/users/all?sortBy=_id&page=${this.page}`);
            this.users = res.data.data;
            this.pageCount = res.data.totalPages;
            this.loading = false;
        }
        catch(e){
            this.error = "Error"
        }
    }
}
</script>